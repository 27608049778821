import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import {
  Loading,
  toCssPrefix,
  OrderStatus,
  getFullOrder,
  getOrderShippingDate,
  CLOrder,
  useLocation,
  breakpoints,
  toWeekDay,
  OrderResume,
  showToast,
} from '@ecommerce/shared'
import styled from 'styled-components'
import Layout from '../components/Layout/LayoutStaticPage'
import { Icon } from '../components/Icon/Icon'
import { PgPageProps } from '../types/PgPages'
import QRSection from '../components/QRSection'
import InfoBadge from '../components/InfoBadge'
import secrets from '../config/secrets'
import { OrderLineItem } from '../components/OrderLineItem'

const { cssPrefix, toPrefix } = toCssPrefix('QRCodePage__')

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: calc(100vh - 150px);

  #qr-section {
    flex-direction: column;

    .${cssPrefix}QR-section-image {
      width: 290px;
      height: 290px;
      border-color: ${({ theme }) => theme.colors.medium};
    }
  }

  .${cssPrefix} {
    &inner {
      min-height: calc(100vh - 250px);
      width: 100%;
      max-width: 640px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background: ${({ theme }) => theme.colors.white};
      padding: 60px 20px;
    }

    &loader {
      width: 84px;
      height: 84px;
      position: absolute;
      top: calc(50% - 42px);
    }

    &content {
      width: 100%;
      padding: 0 20px;
    }

    &order-number,
    &order-delivery-date {
      font-weight: bold;
      font-size: 15px;
      margin: 0 auto 8px;
      display: flex;
      align-items: center;
      width: 230px;

      svg {
        margin-right: 10px;
      }
    }

    &badge {
      margin: 20px 0;
    }

    &order-info {
      margin-bottom: 25px;
      padding: 0 26px;

      p {
        margin: 2px 0;
      }
    }

    &order-content {
      border: 2px solid ${(props) => props.theme.colors.dark10};
      border-radius: ${({ theme }) => theme.borderRadius};
    }

    &order-content-header {
      background: ${({ theme }) => theme.colors.primary};
      height: 70px;
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      align-items: center;
      padding: 0 30px;
      margin: -2px -2px 25px;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;

      &-icon {
        margin-right: 30px;
        width: 45px;
        height: 45px;
      }

      &-text {
        span {
          display: block;

          &.number {
            font-weight: bold;
            font-size: 17px;
          }

          &.count {
            font-size: 14px;
          }
        }
      }
    }

    &order-resume {
      border-top: 2px solid ${(props) => props.theme.colors.dark10};
      padding-top: 10px;

      &::after {
        display: none;
      }
    }

    &go-home-button,
    &signup-button {
      width: 100%;
      display: block;
      margin: 15px auto;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    background: ${({ theme }) => theme.colors.bodyBackground.alt};
    padding: 70px 0;

    .${cssPrefix} {
      &inner {
        padding: 60px 100px;
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      }
    }
  }
`
const { SHIPPING_COST } = secrets

const QRCodePage = ({ location, pageContext: { country } }: PgPageProps) => {
  const [order, setOrder] = useState<CLOrder & { shippingDate: string }>()
  const [isLoading, setIsLoading] = useState(true)

  const params = new URLSearchParams(location.search)
  const orderId = params.get('id')

  const showToastNotification = () => {
    return showToast({ content: 'Ha habido un error, por favor intenta de nuevo mas tarde', type: 'error' })
  }

  const { toCurrency } = useLocation()

  const navigateTo = (slug: string) => {
    navigate(slug)
  }

  const navigateBack = () => navigateTo('/')

  useEffect(() => {
    if (!orderId || !country) {
      return navigateBack()
    }

    const fetchData = async () => {
      try {
        const fullOrder = await getFullOrder({ orderId, includeLineItems: true, country })
        const shippingDate = getOrderShippingDate(fullOrder)

        if (fullOrder.attributes.status !== OrderStatus.APPROVED) {
          return navigateBack()
        }

        setOrder({ ...fullOrder, shippingDate })
        setIsLoading(false)
      } catch (error) {
        setTimeout(() => {
          showToastNotification()
          return navigateBack()
        }, 2500)
      }
    }

    fetchData()
  }, [])

  const qrRaw = order?.attributes?.metadata?.qrCodeRaw
  const qrUrl = order?.attributes?.metadata?.qrCode

  const orderItems = order?.relationships?.line_items?.objects ?? []
  const discounts =
    orderItems
      .map((item) =>
        item?.attributes?.metadata?.originalPrice && item?.attributes?.metadata?.price
          ? item?.attributes?.metadata?.originalPrice - item?.attributes?.metadata?.price
          : 0,
      )
      .filter((discount) => discount > 0)
      .reduce((a, b) => a + b, 0) * orderItems.length
  const promotionDiscounts = Math.abs(order?.attributes.discount_amount_cents ?? 0)
  const subtotal = (order?.attributes.total_amount_cents ?? 0) + discounts + promotionDiscounts
  const shippingAddress = order?.relationships?.shipping_address?.object?.attributes

  return (
    <Layout title="Codigo QR">
      <Wrapper className={cssPrefix}>
        <div className={toPrefix('inner')}>
          {isLoading || !orderId || !order ? (
            <Loading className={toPrefix('loader')} />
          ) : (
            <div className={toPrefix('content')}>
              <p className={toPrefix('order-number')}>
                <Icon opacity=".4" iconId="qr" size="15" />
                {`QR Pedido Nº ${order?.attributes?.number}`}
              </p>
              {order?.shippingDate && (
                <p className={toPrefix('order-delivery-date')}>
                  <Icon iconId="truck" fillColor="dark75" size="20" />
                  {`Despacho el ${order?.shippingDate.split('-').reverse().join('-')}`}
                </p>
              )}
              <QRSection
                id="qr-section"
                className={toPrefix('QR-section')}
                orderId={orderId}
                qrCodeRaw={qrRaw}
                qrCodeUrl={qrUrl}
              />
              <InfoBadge
                className={toPrefix('badge')}
                text="Deberás mostrar el <b>código QR</b> al recibir tu pedido para realizar la transferencia electrónica."
              />

              <div className={toPrefix('order-info')}>
                <h3>DETALLE DE TU PEDIDO</h3>
                <p>{`Pedido para ${shippingAddress?.first_name} ${shippingAddress?.last_name}`}</p>
                <p>{`Teléfono: ${shippingAddress?.phone}`}</p>
                <p>{`${shippingAddress?.line_1}, ${shippingAddress?.city}`}</p>
                <p>{`${toWeekDay(order.shippingDate)} entre 9:00 y 18:00 hrs`}</p>
              </div>
              <section className={toPrefix('order-content')}>
                <div className={toPrefix('order-content-header')}>
                  <Icon iconId="cart_0" className={toPrefix('order-content-header-icon')} />
                  <p className={toPrefix('order-content-header-text')}>
                    <span className="number">{`Pedido Nº ${order?.attributes?.number}`}</span>
                    <span className="count">{`${orderItems.length} productos`}</span>
                  </p>
                </div>
                <div className={toPrefix('order-items')}>
                  {orderItems.map((item) => (
                    <OrderLineItem className={toPrefix('order-line-item')} key={item.id} orderLine={item} />
                  ))}
                </div>
                {/* TPODO: Check raw discount on order metadata */}
                <OrderResume
                  className={toPrefix('order-resume')}
                  showHeader={false}
                  subTotal={toCurrency(subtotal)}
                  orderTotal={toCurrency(order.attributes.total_amount_cents)}
                  shipping={toCurrency(SHIPPING_COST)}
                  discount={toCurrency(discounts)}
                  couponDiscount={promotionDiscounts > 0 ? toCurrency(promotionDiscounts) : undefined}
                  totalText="Total"
                  isRawDiscount={false}
                />
              </section>
            </div>
          )}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default QRCodePage
